import { INGESTION_RESOURCE, IMPORT_RESOURCE } from '@/services/keys';
import { api } from '.';

export const getResources = insertionId => ({
  [INGESTION_RESOURCE]: insertionId,
  [IMPORT_RESOURCE]: undefined,
});

/**
 *
 * @param {PlatformReimport} data
 * @return {Promise.<data:object>}
 */
export function reloadPlatformData(data) {
  const partialUrl = api.createUrl(getResources());

  return api.create(partialUrl, data.payload());
}
