import Column from '@/model/shared/Column';

export default {
  headers: [
    new Column('NAME', 'name').setClass('font-bold'),
    new Column('STATUS', 'status'),
    new Column('PROVIDER', 'provider'),
    new Column('UPDATE', 'updated'),
    new Column('OPEN FEE', 'openFee').toRight(),
    new Column('DEAL FEE', 'dealFee').toRight(),
    new Column('ACTIONS', 'actions').setClass('w-4'),
  ],
};
