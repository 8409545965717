<template>
  <div>
    <asterix-section :status="sectionStatus">
      <template #content>
        <sun-data-table
          :headers="headers"
          :content="items"
          hoverable
          :loading="isLoading"
          class="mt-2"
          @sort="onSortTable"
        >
          <template #[`col.provider`]="{ item }">
            <sun-data-table-cell>
              {{ item.provider.name }}
            </sun-data-table-cell>
          </template>

          <template #[`col.openFee`]="{ item, columnClass }">
            <sun-data-table-cell :class="columnClass">
              <span v-if="item.platformFees[0]"> {{ item.platformFees[0].openFee || 0 }}% </span>
              <span v-else> - </span>
            </sun-data-table-cell>
          </template>

          <template #[`col.dealFee`]="{ item, columnClass }">
            <sun-data-table-cell :class="columnClass">
              <span v-if="item.platformFees[0]"> {{ item.platformFees[0].dealsFee || 0 }}% </span>
              <span v-else> - </span>
            </sun-data-table-cell>
          </template>

          <template #[`col.updated`]="{ item }">
            <sun-data-table-cell>
              <date-tooltip v-if="item.updatedAt" :date="item.updatedAt" class="inline-flex" hide-popover />
              <span v-else>-</span>
            </sun-data-table-cell>
          </template>

          <template #[`col.status`]="{ item }">
            <sun-data-table-cell>
              <status-pill :status="item.status.id" :color="item.status.color" />
            </sun-data-table-cell>
          </template>

          <template #[`col.actions`]="{ item, columnClass }">
            <sun-data-table-cell :class="columnClass">
              <table-action-menu :actions="actions" :item="item" :items="items" @click="onActionClick($event, item)" />
            </sun-data-table-cell>
          </template>

          <template #empty>
            <asterix-no-data class="bg-white" />
          </template>
        </sun-data-table>

        <sun-pagination-page
          :key="currentPage"
          :options-per-page="availablePageSizes"
          :total-pages="totalPages"
          :total-items="totalItems"
          :current-page="currentPage"
          :value="itemsPerPage"
          class="my-6"
          @changePage="goToPage({ page: $event })"
          @changePerPage="changeItemsPerPage"
        />
      </template>
    </asterix-section>

    <delete-modal :open="showModal" @cancel="closeDeleteModal" @confirm="deleteElement">
      <template #title> {{ i18n.en.names.platform.actions.delete }}</template>
      <template #description> {{ i18n.en.names.platform.messages.deleteConfirm }}</template>
    </delete-modal>

    <platform-reload-modal
      v-if="showReloadModal"
      :platform-id="selectedId"
      :reload-fn="reloadPlatformData"
      @cancel="closeReloadModal"
      @reload="updatePlatformStatus"
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { deleteModalMixin } from '@/mixins/delete/deleteModal';
import { indexMixin } from '@/mixins/index/indexMixin';
import { PLATFORM_RESOURCE } from '@/services/keys';
import { DashboardSspApi } from '@/services/api/DashboardSspApi';
import { COMMON_TEXTS } from '@/i18n/commonTexts';
import { getConnectedPlatforms } from '@/services/modules/dashboardSSP/platform';
import { activeClientMixin } from '@/mixins/common/activeClientMixin';
import AsterixSection from '@/components/templates/AsterixSection';
import { CREATE_TOAST } from '@/store/modules/toast/keys';
import { edit as platformEdit } from '@/router/private/modules/dashboardSSP/platforms/edit';
import QueryParamsBuilder from '@/model/shared/QueryParamsBuilder';
import DeleteModal from '@/components/organisms/shared/DeleteModal';
import CONFIG from './config';
import PlatformReloadModal from '@/components/organisms/modules/dashboardSSP/platform/PlatformReloadModal';
import { Toast } from '@/model/shared/Toast';
import { MIN_HOURS_SINCE_LAST_IMPORT } from '@/entities/dashboardSSP/Platform';
import { reloadPlatformData } from '@/services/modules/dashboardSSP/platform/reloadPlatformData';

export default {
  name: 'PlatformList',
  components: {
    PlatformReloadModal,
    AsterixSection,
    DateTooltip: () => import('@/components/atoms/DateTooltip/DateTooltip'),
    StatusPill: () => import('@/components/atoms/StatusPill'),
    TableActionMenu: () => import('@/components/organisms/shared/TableActionMenu'),
    AsterixNoData: () => import('@/components/organisms/shared/AsterixNoData'),
    DeleteModal,
  },
  mixins: [activeClientMixin, deleteModalMixin, indexMixin],
  data: () => ({
    isLoading: false,
    headers: CONFIG.headers,
    items: [],
    totalPages: 0,
    availablePageSizes: [25, 50, 100, 200],
    currentApi: new DashboardSspApi(),
    resource: PLATFORM_RESOURCE,
    i18n: COMMON_TEXTS,
    showReloadModal: false,
    actions: [{ name: 'Reload' }, { name: 'Edit' }, { name: 'Delete' }],
  }),
  created() {
    this.getTableItems();
  },
  methods: {
    reloadPlatformData,
    ...mapActions({
      createToast: CREATE_TOAST,
    }),
    async onActionClick({ name }, item) {
      switch (name) {
        case 'Edit':
          await this.$router.push({ name: platformEdit.name, params: { platformId: item.id } });
          break;
        case 'Delete':
          this.deletePlatform(item.id);
          break;
        case 'Reload':
          this.openReloadModal(item);
          break;
      }
    },
    deletePlatform(platformId) {
      this.showModal = true;
      this.selectedId = platformId;
      this.openModal({ id: platformId });
    },
    closeDeleteModal() {
      this.selectedId = null;
      this.showModal = false;
    },
    deleteElement() {
      this.deleteElementById();
    },
    openReloadModal(platform) {
      if (!platform.canReloadByStatusRestriction()) {
        this.createToast(Toast.error('Oops! Something went wrong', 'You must wait for the current load to finish'));
        return false;
      }
      if (!platform.canReloadByTimeRestriction()) {
        this.createToast(
          Toast.error(
            'Oops! Something went wrong',
            `You must wait ${MIN_HOURS_SINCE_LAST_IMPORT} hours since the last import`
          )
        );
        return false;
      }
      this.showReloadModal = true;
      this.selectedId = platform.id;
    },
    closeReloadModal() {
      this.selectedId = null;
      this.showReloadModal = false;
    },
    updatePlatformStatus(platformId) {
      const reloadedPlatform = this.items.find(platform => platform.id === platformId);
      reloadedPlatform?.reload();
    },
    async getTableItems() {
      this.isLoading = true;
      const auxParams = new QueryParamsBuilder(this.currentPage, this.itemsPerPage);
      auxParams.addFilter('client.id', this.activeClient?.id);
      const { data, isCancel } = await this.getItemsFromAPI(getConnectedPlatforms, auxParams);
      this.items = data;
      if (!isCancel) {
        this.isLoading = false;
      }
    },
  },
};
</script>
