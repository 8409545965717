var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("asterix-section", {
        attrs: { status: _vm.sectionStatus },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function () {
              return [
                _c("sun-data-table", {
                  staticClass: "mt-2",
                  attrs: {
                    headers: _vm.headers,
                    content: _vm.items,
                    hoverable: "",
                    loading: _vm.isLoading,
                  },
                  on: { sort: _vm.onSortTable },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: `col.provider`,
                        fn: function ({ item }) {
                          return [
                            _c("sun-data-table-cell", [
                              _vm._v(" " + _vm._s(item.provider.name) + " "),
                            ]),
                          ]
                        },
                      },
                      {
                        key: `col.openFee`,
                        fn: function ({ item, columnClass }) {
                          return [
                            _c("sun-data-table-cell", { class: columnClass }, [
                              item.platformFees[0]
                                ? _c("span", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          item.platformFees[0].openFee || 0
                                        ) +
                                        "% "
                                    ),
                                  ])
                                : _c("span", [_vm._v(" - ")]),
                            ]),
                          ]
                        },
                      },
                      {
                        key: `col.dealFee`,
                        fn: function ({ item, columnClass }) {
                          return [
                            _c("sun-data-table-cell", { class: columnClass }, [
                              item.platformFees[0]
                                ? _c("span", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          item.platformFees[0].dealsFee || 0
                                        ) +
                                        "% "
                                    ),
                                  ])
                                : _c("span", [_vm._v(" - ")]),
                            ]),
                          ]
                        },
                      },
                      {
                        key: `col.updated`,
                        fn: function ({ item }) {
                          return [
                            _c(
                              "sun-data-table-cell",
                              [
                                item.updatedAt
                                  ? _c("date-tooltip", {
                                      staticClass: "inline-flex",
                                      attrs: {
                                        date: item.updatedAt,
                                        "hide-popover": "",
                                      },
                                    })
                                  : _c("span", [_vm._v("-")]),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                      {
                        key: `col.status`,
                        fn: function ({ item }) {
                          return [
                            _c(
                              "sun-data-table-cell",
                              [
                                _c("status-pill", {
                                  attrs: {
                                    status: item.status.id,
                                    color: item.status.color,
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                      {
                        key: `col.actions`,
                        fn: function ({ item, columnClass }) {
                          return [
                            _c(
                              "sun-data-table-cell",
                              { class: columnClass },
                              [
                                _c("table-action-menu", {
                                  attrs: {
                                    actions: _vm.actions,
                                    item: item,
                                    items: _vm.items,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.onActionClick($event, item)
                                    },
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                      {
                        key: "empty",
                        fn: function () {
                          return [
                            _c("asterix-no-data", { staticClass: "bg-white" }),
                          ]
                        },
                        proxy: true,
                      },
                    ],
                    null,
                    true
                  ),
                }),
                _c("sun-pagination-page", {
                  key: _vm.currentPage,
                  staticClass: "my-6",
                  attrs: {
                    "options-per-page": _vm.availablePageSizes,
                    "total-pages": _vm.totalPages,
                    "total-items": _vm.totalItems,
                    "current-page": _vm.currentPage,
                    value: _vm.itemsPerPage,
                  },
                  on: {
                    changePage: function ($event) {
                      return _vm.goToPage({ page: $event })
                    },
                    changePerPage: _vm.changeItemsPerPage,
                  },
                }),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("delete-modal", {
        attrs: { open: _vm.showModal },
        on: { cancel: _vm.closeDeleteModal, confirm: _vm.deleteElement },
        scopedSlots: _vm._u([
          {
            key: "title",
            fn: function () {
              return [
                _vm._v(" " + _vm._s(_vm.i18n.en.names.platform.actions.delete)),
              ]
            },
            proxy: true,
          },
          {
            key: "description",
            fn: function () {
              return [
                _vm._v(
                  " " +
                    _vm._s(_vm.i18n.en.names.platform.messages.deleteConfirm)
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _vm.showReloadModal
        ? _c("platform-reload-modal", {
            attrs: {
              "platform-id": _vm.selectedId,
              "reload-fn": _vm.reloadPlatformData,
            },
            on: {
              cancel: _vm.closeReloadModal,
              reload: _vm.updatePlatformStatus,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }